.content-main-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.home-i-b-t {
  font-family: "Montserrat";
  line-height: 1.4em;
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
  color: #f4f3f2;

  letter-spacing: 0.1em;
}
.choco-me-flaver {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5rem;
  width: 100%;
  height: auto;
}
.left-side-choco-me,
.right-side-choco-me {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: auto;
}

.l-c-img {
  width: 450px;
  height: 550px;
  object-fit: cover;
}
.r-c-head {
  width: 80%;
  font-size: 40px;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
  letter-spacing: 0.05em;
  color: #756f63;
  line-height: 1.4em;
}
.qulity-r-c-head {
  font-size: 40px;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
  letter-spacing: 0.05em;
  color: #756f63;
  line-height: 1.4em;
}
.r-c-p {
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
  letter-spacing: 0.1em;
  color: #756f63;
  line-height: 1.4em;
}
.l-c-para {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
  padding: 5rem;
  line-height: 1.7em;
  letter-spacing: 0.01em;
  color: #756f63;
}
.r-c-para {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat";
  text-align: center;
  padding: 3rem;
  line-height: 1.7em;
  letter-spacing: 0.01em;
  color: #756f63;
}
.qulity-l-c-para {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat";
  margin-left: 5rem;
  padding: 2rem;
  line-height: 1.7em;
  letter-spacing: 0.01em;
  color: #756f63;
}

.h-c-parallax {
  width: 100%;
  height: 500px;
  background-image: url("../../assets/mimg2.jpg");
  background-position: center;
  background-attachment: fixed;

  background-size: cover;
}
@media screen and (max-width: 768px) {
  .content-main-block {
    width: 100%;
    height: auto;
  }
  .home-i-b-t {
    font-size: 16px;
  }
  .choco-me-flaver {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0px;
  }
  .left-side-choco-me {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .right-side-choco-me {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .l-c-img {
    width: 100%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .r-c-head {
    font-size: 24px;
    margin-top: 20px;
  }
  .qulity-r-c-head {
    margin-top: 20px;
    font-size: 24px;
  }
  .r-c-p {
    font-size: 14px;
  }
  .l-c-para {
    width: 100%;
    height: auto;
  }
  .r-c-para {
    width: 100%;
    padding: 20px;
  }
  .h-c-parallax {
    width: 100%;
    height: 500px;
    background-position: center;
    background-attachment: fixed;

    background-size: cover;
  }
}
