.home-main-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}

.home-img-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /* background-color: rgb(248, 13, 13); */
  background-image: url("../../assets/profile.jpg");
  background-position: center;
  background-attachment: fixed;

  background-size: cover;
}

.home-img-1 {
  width: 100%;
  height: auto;
  object-fit: contain;
}
.home-img-text {
  width: 700px;
  height: 300px;
}
.h-p-1 {
  font-family: "Montserrat";
  line-height: 1.4em;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #f4f3f2;

  letter-spacing: 0.4em;
}
.h-h-1 {
  font-size: 110px;
  font-family: "Montserrat";
  font-weight: 500;
  text-align: center;
  color: #f4f3f2;
  line-height: 0.8em;
  letter-spacing: 0.01em;
  text-align: center;
}
.sub-text {
  font-size: 36px;
  font-weight: 400;
  font-family: "Montserrat";
}
@media screen and (max-width: 768px) {
  .home-main-block {
    width: 100vw;
    height: auto;
  }
  .home-img-block {
    width: 100%;
    height: 80vh;
    background-position: bottom;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home-img-1 {
    width: 100%;
    height: auto;
  }
  .home-img-text {
    width: 100%;
    height: auto;
  }
  .h-p-1 {
    font-size: 14px;
  }
  .h-h-1 {
    font-size: 36px;
    font-weight: 700;
  }
  .sub-text {
    font-size: 14px;
    font-weight: 600;
  }
}
