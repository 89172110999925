.footer-main-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(145, 135, 102);
  width: 100%;
  padding: 20px 0 20px 0;
}
.up-arrow-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
}
.up-arrow {
  color: #f4f3f2;
  width: 50px;
  height: 50px;
}
.top-link {
  text-decoration: none;
}
.footer-head {
  font-family: "Poppins";
  font-weight: 300;
  line-height: 1.8em;
  text-align: center;
  font-size: 15px;
  color: #f4f3f2;
  width: 300px;
}
.top-text {
  font-family: "Poppins";
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-size: 14px;
  color: #f4f3f2;
}
.atomicloops {
  text-decoration: none;
  color: #f4f3f2;
}
.footer-social-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.footer-links {
  width: 26px;
  height: 26px;
  color: #f4f3f2;
  margin: 1rem 1rem 1rem auto;
}
@media screen and (max-width: 600px) {
  .footer-main-block {
    width: 100%;
    height: auto;
  }
  .up-arrow-block {
    width: 100%;
    height: auto;
  }
  .up-arrow {
    width: 50px;
    height: 50px;
  }
  .top-link {
    text-decoration: none;
  }
  .footer-head {
    font-size: 14px;
  }
  .top-text {
    font-size: 12px;
  }
  .footer-social-links {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .footer-links {
    width: 35px;
    height: 35px;
  }
}
