.navbar-main-block {
  width: 100%;
  height: 76px;

  background-color: rgb(145, 135, 102);
}
.navbar-main-block :hover {
  color: #ffffff;
}
.toggle {
  color: #000000;
  background-color: #f4f3f2;
}
.weblogo {
  height: 70px;
  object-fit: contain;
}

.nav-links {
  font-size: 18px;
  color: #f4f3f2;
  line-height: 40px;
  letter-spacing: 0.1em;
  margin-right: 20px;
  cursor: pointer;
  text-transform: uppercase;
}
.nav-icons {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
@media screen and (max-width: 768px) {
  .navbar-main-block {
    width: 100%;
    height: auto;
    position: none;
    background-color: rgb(145, 135, 102);
  }
  .navbar-main-block :hover {
    color: #f4f3f2;
  }
  .toggle {
    color: #f4f3f2;
    background-color: rgb(145, 135, 102);
    border: none;
  }
  .nav-links {
    font-size: 16px;
    margin: 0px;
    text-align: center;
    color: #f4f3f2;
  }
  .nav-icons {
    width: 24px;
    height: 24px;
  }
}
