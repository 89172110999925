.shop-main-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* background-image: url("../../assets/mimg5.jpg"); */
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  height: 100%;
  overflow: hidden;
  padding: 3rem;
}
.shop-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
}
.shop-card {
  width: 250px;
  height: 250px;
  margin-right: 20px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
}
.shop-card-img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  object-position: 50% 50%;
}
.card-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
}
.card-name,
.card-cost {
  font-family: "Montserrat";
  font-size: 14px;
  font-size: 400;
  color: rgb(145, 135, 102);
  margin: 10px;
}
.info-btn {
  font-family: "Montserrat";
  font-size: 14px;
  font-size: 400;
  position: absolute;
  top: 87%;
  left: 0;
  right: 0;
  background-color: rgb(145, 135, 102);
  color: #f4f3f2;
  width: 250px;
  height: 36px;
  border: none;
}
.card-modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 600px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;

  --bs-modal-border-width: 0px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25remrgba (0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;

  --bs-modal-header-border-width: 0px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;

  --bs-modal-footer-border-width: 0px;

  top: 20%;
  left: 0;

  width: 100%;
  height: 600px;
  overflow-x: hidden;
  overflow-y: auto;
  outline: none;
}
.modal-body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  object-fit: cover;
  padding: 0px;
  margin: 20px auto 20px auto;
}
.left-side-modal {
  width: 250px;
  height: 250px;
  object-fit: contain;
}
.modal-name {
  font-family: "Montserrat";
  font-size: 14px;
  font-size: 400;
  color: #272521;
  margin: 5px;
}
.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: rgba(0, 0, 0, 0.126);
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(145, 135, 102, 0.388);
}
.btn-secondary {
  width: 100px;
  background-color: rgb(145, 135, 102);
  font-family: "Montserrat";
  font-size: 14px;
  font-size: 400;
  color: #ffffff;
  margin: 5px;
}
@media screen and (max-width: 600px) {
  .shop-main-block {
    width: 100%;
    height: auto;
    padding: 0px;
  }
  .shop-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .shop-card {
    width: 100%;
    height: auto;
    margin: 0px;
    position: relative;
  }
  .shop-card-img {
    width: 100%;
    height: auto;
    object-position: auto;
    margin-top: 20px;
  }
  .card-text {
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 10px auto auto auto;
  }
  .card-name,
  .card-cost {
    font-size: 14px;
  }
  .info-btn {
    width: 100%;
    height: 44px;
    position: relative;
    top: 82%;
    left: 0;
    right: 0;
  }
  .card-modal {
    width: 100%;
    height: auto;
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
  }
  .left-side-modal,
  .right-side-modal {
    width: 100%;
    height: auto;
    text-align: center;
  }
  .modal-name {
    font-size: 14px;
    margin-top: 20px;
  }
  .btn-secondary {
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 14px;
  }
}
